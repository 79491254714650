// Core variables and mixins
@import "res/scss/__variables.scss";
@import "res/scss/__mixins.scss";

.main-effect {
  background-image:url(/views/res/imgs/main/bg_main_effect.jpg);
  background-repeat:no-repeat;
  background-position:center;
  background-size:cover;

  .main-effect-slide {
    @include media-query-min(lg){
      overflow:hidden;
    }
    .sliderNav {
      position: relative;
      max-width:479px;
      min-height: 51px;
      margin:0 auto 30px;
      .slick-list {
        transform: none;
        @include media-query-min(lg){
          overflow:visible;
        }
      }
      .slick-arrow {
        &.slick-prev {
          left:0;
          @include media-query-min(md){
            left:38px;
          }
        }
        &.slick-next {
          right:0;
          @include media-query-min(md){
            right:38px;
          }
        }
      }
    }
    .slide-logo {
      height:49px;
      margin:0 12.5px;
      background-repeat:no-repeat;
      background-position:center;
      background-size:contain;
      &.one {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_thesaem_m_on.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_thesaem_on.png);
        }
        @include media-query-min(lg){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_thesaem.png);
        }
      }
      &.two {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hubasemall_m.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hubasemall.png);
        }
      }
      &.three {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hotel_m.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hotel.png);
        }
      }
      &.slick-current.one {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_thesaem_m_on.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_thesaem_on.png);
        }
      }
      &.slick-current.two {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hubasemall_m_on.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hubasemall_on.png);
        }
      }
      &.slick-current.three {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hotel_m_on.png);
        @include media-query-min(sm){
          background-image:url(/views/res/imgs/main/effect_slide/effect_slide_logo_hotel_on.png);
        }
      }
    }
    .slidefor-style {
      background-color:#fff;
      padding:33px 20px;
      text-align: center;
      @include media-query-min(lg){
        padding:66px 105px 60px;
      }
      .title {
        padding:15px 0 20px;
        margin-bottom:13px;
        font-size:20px;
        font-weight:600;
        color:#000;
        @include media-query-min(lg){
          font-size:24px;
        }
        border-bottom:1px solid #ddd;
      }
      .desc {
        line-height:1.75;
        font-size:16px;
        letter-spacing: -0.025em;
        @include media-query-min(lg){
          padding: 0 15px;
          font-size:18px;
        }
      }
    }
  }
}

.common-main-slide {
  .common-slide-num {
    font-size:16px;
    font-family: $font-lato;
    letter-spacing: -0.05em;
    color:#aaa;
    strong {
      color:$g_point-theme2-color;
      &:after {
        display:inline-block;
        content:'';
        width:20px;
        height:1px;
        background-color:#aaa;
        margin-left:5px;
        margin-right:5px;
        vertical-align: middle;
      }
    }
  }
  .slick-arrow {
    position:absolute;
    top:calc(50% - 25px);
    width:50px;
    height:50px;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
    font-size:0;
    transition:background .4s;
    z-index:1;
    &.slick-prev {
      background-image:url(/views/res/imgs/main/effect_slide/effect_slide_arrow_left.svg);
      &:hover {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_arrow_left_hover.svg);
      }
    }
    &.slick-next {
      background-image:url(/views/res/imgs/main/effect_slide/effect_slide_arrow_right.svg);
      &:hover {
        background-image:url(/views/res/imgs/main/effect_slide/effect_slide_arrow_right_hover.svg);
      }
    }
  }
}